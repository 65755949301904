import { Button, Form, Typography } from "@mojo/blueprint";
import { ButtonType } from "@mojo/blueprint/Button";
import Input from "@mojo/blueprint/Input/Controlled";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import { GdprApiUrl } from "./Config";

enum State {
    CaptureEmail,
    CaptureVerificationCode,
    Done,
}

const Panel = styled.div`
    border-radius: 1rem;
    padding: 2rem;
    grid-column: 1 / -1;
`;

const UL = styled.ul`
    margin-top: 0;
    font-weight: 500;

    li {
        padding: .3rem 0;
    }
`;

type EmailForm = {
    emailAddress: string;
};

type VerificationCodeForm = {
    verificationCode: string;
};

export function GdprForm() {
    const [formState, setFormState] = useState<State>(State.CaptureEmail);
    const [formBusy, setFormBusy] = useState(false);
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [validCode, setValidCode] = useState<boolean | null>(null);

    const captureEmailForm = useForm<EmailForm>({ mode: "onBlur" });
    const captureVerificationCodeForm = useForm<VerificationCodeForm>({ mode: "onBlur" });

    const HandleEmailCollected: SubmitHandler<EmailForm> = async ({ emailAddress }) => {
        setFormBusy(true);
        setEmailAddress(emailAddress);

        await SendVerificationEmail(emailAddress);

        setFormState(State.CaptureVerificationCode);
        setFormBusy(false);
    };

    const HandleVerificationCodeCollected: SubmitHandler<VerificationCodeForm> = async ({ verificationCode }) => {
        setFormBusy(true);

        const response = await fetch(`${GdprApiUrl}/check-verification-code`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                emailAddress: emailAddress,
                verificationCode: verificationCode,
            }),
        });

        if (response.ok) {
            setFormState(State.Done);
        } else {
            setValidCode(false);
        }

        setFormBusy(false);
    };

    async function SendVerificationEmail(email: string) {
        await fetch(`${GdprApiUrl}/verification-code`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                emailAddress: email,
            }),
        });
    }

    return (
        <>
            <Panel style={{ background: "white" }}>
                {formState !== State.Done &&
                    (
                        <>
                            <Typography.H2>You have the right to ask us to erase your personal data</Typography.H2>
                            <Typography.Body>
                                Provide your email and we'll promptly handle your request in line with the UK GDPR.
                            </Typography.Body>
                            <Typography.Body>
                                We must comply with this request, unless using or storing your personal data is
                                necessary, for example for the following reasons:
                            </Typography.Body>
                            <UL>
                                <li>to comply with legal, regulatory or contractual obligations</li>
                                <li>
                                    for business interests, where we are lawfully allowed to do so, as such to exercise
                                    or defend legal claims
                                </li>
                            </UL>
                            <Typography.Body>
                                For more information on how you can exercise your rights, please see our{" "}
                                <a href="https://mojomortgages.com/privacy-policy/" target="_blank">privacy policy</a>.
                            </Typography.Body>
                            <Panel style={{ background: "#F0F4FF" }}>
                                <>
                                    {formState === State.CaptureEmail &&
                                        (
                                            <Form form={captureEmailForm} onSubmit={HandleEmailCollected}>
                                                <Typography.Body>
                                                    We're required to verify your identity before we process your
                                                    request. To do this, please enter the email address you used during
                                                    your initial enquiry, and we will send you a confirmation code
                                                </Typography.Body>
                                                <Form.Item
                                                    label="Email Address"
                                                    name="emailAddress"
                                                    rules={{
                                                        required: true,
                                                        pattern: {
                                                            value: /^[^@]+@[^@]+\.[^@]+$/,
                                                            message: "Must be a valid email address",
                                                        },
                                                    }}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Button
                                                    disabled={!captureEmailForm.formState.isValid}
                                                    isLoading={formBusy}
                                                    type="submit"
                                                >
                                                    Send confirmation code
                                                </Button>
                                            </Form>
                                        )}
                                    {formState === State.CaptureVerificationCode &&
                                        (
                                            <>
                                                <Form
                                                    form={captureVerificationCodeForm}
                                                    onSubmit={HandleVerificationCodeCollected}
                                                >
                                                    <Typography.Body>
                                                        Enter the 6-digit code we have just sent to your email
                                                    </Typography.Body>
                                                    <Form.Item
                                                        label="Verification Code"
                                                        name="verificationCode"
                                                        rules={{
                                                            required: true,
                                                            pattern: {
                                                                value: /^[0-9]{6}$/,
                                                                message: "Must consist of six digits",
                                                            },
                                                        }}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                    {validCode === false &&
                                                        (
                                                            <p style={{ color: "red" }}>
                                                                An error has occurred. The verification code you have
                                                                entered may be incorrect
                                                            </p>
                                                        )}
                                                    <div style={{ display: "flex" }}>
                                                        <Button
                                                            disabled={!captureVerificationCodeForm.formState.isValid}
                                                            isLoading={formBusy}
                                                            type="submit"
                                                        >
                                                            Submit
                                                        </Button>
                                                        <Button
                                                            onClick={() => SendVerificationEmail(emailAddress)}
                                                            variant={ButtonType.Link}
                                                            type="button"
                                                        >
                                                            Resend email
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </>
                                        )}
                                </>
                            </Panel>
                        </>
                    )}

                {formState === State.Done &&
                    (
                        <>
                            <Typography.H2>Thank you</Typography.H2>
                            <Typography.Body>We’ve received your request to erase your personal data.</Typography.Body>
                            <Typography.Body>
                                While we strive to fulfil all erasure requests, there may be exceptions, and if
                                applicable, we will provide a clear explanation in our response to you. Your privacy
                                matters to us, and we are committed to treating your request with care and transparency.
                            </Typography.Body>
                            <Typography.Body>
                                For more information on how you can exercise your rights, please see our{" "}
                                <a href="https://mojomortgages.com/privacy-policy/" target="_blank">privacy policy</a>.
                            </Typography.Body>
                            <Typography.Body>
                                You will receive confirmation via email with the outcome of your request.
                            </Typography.Body>
                        </>
                    )}
            </Panel>
        </>
    );
}
