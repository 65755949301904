import * as React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import isFunction from "lodash/isFunction";
import { BrandProp } from "@mojo/types";
import { animated, useTransition, config } from "@react-spring/web";
import Typography from "../Typography";
import {
  MojoModalContent,
  VanillaModalButtonContainer,
  VanillaModalCancelButton,
  VanillaModalContent,
  VanillaModalDescriptionContent,
  VanillaModalOverlay,
  VanillaModalTitle,
} from "./styles/base.css";

export type ModalProps = Omit<DialogPrimitive.DialogContentProps, "content"> &
  BrandProp & {
    children?: React.ReactNode;
    /**
     * Control the state of the modal from outside the modal itself
     */
    open?: boolean;
    /**
     * Customise the overlay's animation
     */
    overlayAnimation?: Parameters<typeof useTransition>[1];
    /**
     * Customise the modal's animation
     */
    modalAnimation?: Parameters<typeof useTransition>[1];
    closeProps?: DialogPrimitive.DialogCloseProps;
    descriptionProps?: DialogPrimitive.DialogDescriptionProps;
    titleProps?: DialogPrimitive.DialogTitleProps;
    rootProps?: DialogPrimitive.DialogProps;
    overlayProps?: DialogPrimitive.DialogOverlayProps;
    /**
     * Text that will be displayed in the modal's header
     */
    title?: string;
    /**
     * The main content of the modal. Render props to control the modal's state are passed to it.
     */
    description?: string;
    /**
     * The main content of the modal. Render props to control the modal's state are passed to it.
     */
    content?:
      | ((
          open: boolean,
          setOpen: React.Dispatch<React.SetStateAction<boolean>>
        ) => React.ReactNode)
      | React.ReactNode;
    /**
     * The modal's controls. Render props to control the modal's state are passed to it.
     */
    controls?:
      | React.ReactNode
      | ((
          open: boolean,
          setOpen: React.Dispatch<React.SetStateAction<boolean>>
        ) => React.ReactNode);
  };

/**
 * An accessible modal powered by Radix-UI.
 * Check the documentation https://www.radix-ui.com/docs/primitives/components/alert-dialog
 */
export const Modal = ({
  children,
  title,
  description,
  content,
  controls,
  rootProps,
  overlayProps,
  overlayAnimation = {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  },
  modalAnimation = {
    from: { transform: `scale(0.95) translate(-50%, -50%)`, opacity: 0 },
    enter: { transform: `scale(1) translate(-50%, -50%)`, opacity: 1 },
    leave: { transform: `scale(0.95) translate(-50%, -50%)`, opacity: 0 },
    config: config.stiff,
  },
  closeProps,
  descriptionProps,
  titleProps,
  brand = "Mojo",
  open,
  ...rest
}: ModalProps) => {
  const [openState, setOpenState] = React.useState(false);

  React.useEffect(() => {
    if (open !== undefined) {
      setOpenState(open);
    }
  }, [open]);

  const overlayTransition = useTransition(openState, overlayAnimation);
  const modalTransition = useTransition(openState, modalAnimation);

  return (
    <DialogPrimitive.Root
      {...rootProps}
      open={openState}
      onOpenChange={(open) => {
        setOpenState(open);
        rootProps?.onOpenChange?.(open);
      }}
    >
      {children !== undefined && (
        <DialogPrimitive.Trigger
          onClick={() => {
            setOpenState(true);
          }}
          asChild
        >
          {children}
        </DialogPrimitive.Trigger>
      )}
      <DialogPrimitive.Portal forceMount>
        {overlayTransition((style, item) =>
          item ? (
            <AnimatedOverlay
              {...overlayProps}
              className={`
                ${overlayProps?.className || ""}
                ${VanillaModalOverlay}
              `}
              style={style}
            />
          ) : (
            <></>
          )
        )}

        {modalTransition((style, item) =>
          item ? (
            <AnimatedContent
              style={style}
              // $cssContentOverride={cssContentOverride}
              asChild
            >
              <div
                {...rest}
                className={`modalContent ${VanillaModalContent({ brand })} ${MojoModalContent} ${rest.className || ""}`}
              >
                {title !== undefined && (
                  <DialogPrimitive.Title
                    className={`modalContent__modalTitle ${VanillaModalTitle}`}
                    asChild
                    {...titleProps}
                  >
                    {/* asChild can't be used if child is a component */}
                    <h2>
                      <Typography.LargeBody as="span" weight={600}>
                        {title}
                      </Typography.LargeBody>
                    </h2>
                  </DialogPrimitive.Title>
                )}
                {description !== undefined && (
                  <DialogPrimitive.Description
                    {...descriptionProps}
                    className={`modalContent__modalDescription ${VanillaModalDescriptionContent}`}
                  >
                    {description}
                  </DialogPrimitive.Description>
                )}
                <div
                  className={`modalContent__modalContent ${VanillaModalDescriptionContent}`}
                  style={{
                    flex: "1 1 0%",
                    position: "relative",
                    overflowY: "auto",
                  }}
                >
                  {isFunction(content)
                    ? content?.(openState, setOpenState)
                    : content}
                </div>

                {controls !== undefined && (
                  <div
                    className={`modalContent__buttonContainer ${VanillaModalButtonContainer}`}
                  >
                    {isFunction(controls)
                      ? controls(openState, setOpenState)
                      : controls}
                  </div>
                )}
                <DialogPrimitive.Close
                  className={`modalContent__cancelButton ${VanillaModalCancelButton}`}
                  asChild
                >
                  <button
                    data-testid="close-icon"
                    onClick={() => {
                      setOpenState(false);
                    }}
                    aria-label="Close modal"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      height="24px"
                      width="24px"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </DialogPrimitive.Close>
              </div>
            </AnimatedContent>
          ) : (
            <></>
          )
        )}
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};

const AnimatedOverlay = animated(DialogPrimitive.Overlay);

const AnimatedContent = animated(DialogPrimitive.Content);

export default Modal;
