import Template, { BasicNav, Grid, Main } from "@mojo/blueprint/Template";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { GdprForm } from "./GdprForm";

export default function() {
    return (
        <Template variant="orange">
            <BasicNav variant="transparent" />
            <Main>
                <Grid>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/*" component={GdprForm} />
                        </Switch>
                    </BrowserRouter>
                </Grid>
            </Main>
        </Template>
    );
}
