import { colors, MojoColors } from "./colors/mojo";
import { uswitchColors, UswitchColors } from "./colors/uswitch";
import { moneyColors, MoneyColors } from "./colors/money";
import { confusedColors, ConfusedColors } from "./colors/confused";
import { Breakpoints, devices } from "./devices";
import { size, Size } from "./size";
import { spacing, Spacing } from "./spacing";
import { Shadows, shadows } from "./shadows";

interface BaseThemeConfig {
  [k: string]: any;
}

export interface ThemeConfig extends BaseThemeConfig {
  /**
   * All Mojo and partner colors.
   * Mojo colors are duplicated under the `Mojo` object as well.
   */
  colors: MojoColors & {
    Uswitch: UswitchColors;
    Mojo: MojoColors;
    Money: MoneyColors;
    Confused: ConfusedColors;
  };

  /**
   * Spacing options, to be used for margins, paddings, etc.
   */
  spacing: Spacing;

  /**
   * Pre-defined breakpoints
   */
  breakpoints: Breakpoints;

  /**
   * Pre-defined border radius sizes
   */
  radius: Size;

  /**
   * Pre-defined box-shadow sizes
   */
  shadows: Shadows;
}

const mojoColors = {
  ...colors,
  primary: {
    one: colors.vivid[500],
    two: colors.neutral[900],
    three: colors.white,
  },
  secondary: {
    one: colors.vivid[0],
    two: colors.peach[100],
    three: colors.yellow[500],
  },
};

export const defaultTheme: ThemeConfig = {
  colors: {
    ...mojoColors,
    Mojo: mojoColors,
    Uswitch: uswitchColors,
    Money: moneyColors,
    Confused: confusedColors,
  },

  spacing: spacing,

  shadows: shadows,

  breakpoints: devices,

  radius: size,
};
