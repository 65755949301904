"use client";
import React, { ReactElement, ReactNode } from "react";
import { BrandProp } from "@mojo/types";
import {
  VanillaButton,
  VanillaButtonIcon,
  VanillaButtonInternal,
  VanillaButtonSpinner,
} from "./styles/base.css";
import {
  MojoVanillaButton,
  MojoVanillaButtonInternal,
} from "./styles/mojo.css";
import { RecipeVariants } from "@vanilla-extract/recipes";
import {
  UswitchVanillaButton,
  UswitchVanillaButtonInternal,
} from "./styles/uswitch.css";
import {
  MoneyVanillaButton,
  MoneyVanillaButtonInternal,
} from "./styles/money.css";
import {
  ConfusedVanillaButton,
  ConfusedVanillaButtonInternal,
} from "./styles/confused.css";

export enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Danger = "danger",
  Link = "link",
  Answer = "answer",
}

type ButtonSizes = "base" | "small";

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  BrandProp & {
    /**
     * What style of button should this be displayed as. Import `ButtonType` and pass it as a prop.
     */
    variant?: ButtonType;
    /**
     * The button's content, same as `children`
     */
    text?: string | ReactElement;
    /**
     * The button's content, same as `text`
     */
    children?: ReactNode;
    /**
     * The size of the button, will change the padding amount.
     */
    size?: ButtonSizes;
    /**
     * Display an icon (as a react component or string) left of the button
     */
    leftIcon?: React.ReactNode | string;
    /**
     * Display an icon (as a react component or string) right of the button
     */
    rightIcon?: React.ReactNode | string;
    /**
     * Displays a loader icon to the right of the button text. If the button has a `rightIcon` prop, it replaces the icon.
     */
    isLoading?: boolean;
  };

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children = "",
      variant = ButtonType.Primary,
      disabled = false,
      brand = "Mojo",
      size = "base",
      leftIcon,
      rightIcon,
      isLoading,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const classNames = [];

    if (disabled) {
      classNames.push("disabled");
    }

    switch (variant) {
      case ButtonType.Primary:
        classNames.push("primary");
        break;
      case ButtonType.Secondary:
        classNames.push("secondary");
        break;
      case ButtonType.Tertiary:
        classNames.push("tertiary");
        break;
      case ButtonType.Danger:
        classNames.push("danger");
        break;
      case ButtonType.Link:
        classNames.push("link");
        break;
      case ButtonType.Answer:
        classNames.push("answer");
        break;
    }

    const renderIconOrSpinner = () => {
      if (rightIcon) {
        return (
          <>
            {isLoading ? (
              <>{isLoading && <LoadIcon />}</>
            ) : (
              <div className={`${VanillaButtonIcon} button__icon--rightIcon`}>
                {rightIcon}
              </div>
            )}
          </>
        );
      } else {
        return <>{isLoading && <LoadIcon />}</>;
      }
    };

    return (
      <button
        ref={ref}
        // $size={size}
        disabled={disabled}
        // $brand={brand}
        {...props}
        className={`
          ${classNames.join(" ")} 
          ${props.className || ""} 
          ${VanillaButton({ size, brand })} 
          ${MojoVanillaButton({ variant: variant as NonNullable<RecipeVariants<typeof MojoVanillaButton>>["variant"] })}
          ${UswitchVanillaButton({ variant: variant as NonNullable<RecipeVariants<typeof MojoVanillaButton>>["variant"] })}
          ${MoneyVanillaButton({ variant: variant as NonNullable<RecipeVariants<typeof MojoVanillaButton>>["variant"] })}
          ${ConfusedVanillaButton({ variant: variant as NonNullable<RecipeVariants<typeof MojoVanillaButton>>["variant"] })}
        `}
      >
        <span
          className={`${VanillaButtonInternal} ${MojoVanillaButtonInternal} ${UswitchVanillaButtonInternal} ${MoneyVanillaButtonInternal} ${ConfusedVanillaButtonInternal}`}
        >
          {leftIcon && (
            <div className={`${VanillaButtonIcon} button__icon--leftIcon`}>
              {leftIcon}
            </div>
          )}
          {children}
          {renderIconOrSpinner()}
        </span>
      </button>
    );
  }
);

const LoadIcon = () => (
  <div
    className={`${VanillaButtonIcon} ${VanillaButtonSpinner} button__icon--spinner`}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      className="spinner"
    >
      <rect width="256" height="256" fill="none" />
      <path
        d="M168,40a97,97,0,0,1,56,88,96,96,0,0,1-192,0A97,97,0,0,1,88,40"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="24"
      />
    </svg>
  </div>
);

export default Button;
