import React, { useContext } from "react";
import get from "lodash/get";
import {
  FieldValues,
  FormProvider,
  RegisterOptions,
  SubmitHandler,
  useForm,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import ctxt from "./context";
import { VanillaError, VanillaItem } from "./base.css";

export type FormProps<T extends FieldValues> = {
  form?: UseFormReturn<T, any>;
  onSubmit?: SubmitHandler<T>;
  children: React.ReactNode;
  debug?: boolean;
} & Omit<React.HTMLAttributes<HTMLFormElement>, "onSubmit">;

/**
 * Renders a form component managed by `react-hook-form`. Its children should be components controlled by `react-hook-form`.
 * @param form - value returned by `useForm`, if unspecified a default useform instance is used
 * @param onSubmit - callback for when form is submitted
 * @param children - components to render within the form
 * @returns
 */
function Form<T extends FieldValues>({
  form,
  onSubmit = () => null,
  children,
  debug,
  ...rest
}: FormProps<T>) {
  const methods = useForm<T>();

  const values = form ? form.watch() : methods.watch();

  React.useEffect(() => {
    if (debug) {
      console.log(values);
    }
  }, [values, debug]);

  const context = { ...(form || methods) };

  return (
    <FormProvider {...context}>
      <form onSubmit={context.handleSubmit(onSubmit)} {...rest}>
        {children}
        <div className={`${VanillaError}`} role="alert">
          {get(context.formState.errors, `root.message`) as unknown as string}
        </div>
      </form>
    </FormProvider>
  );
}

Form.defaultProps = {
  debug: false,
};

const FormItemContextProvider = ctxt.Provider;

/**
 * Contains information about the form item
 */
export const FormItemProvider = ({
  children,
  rules,
  name,
  setValueAs,
}: {
  children: React.ReactNode;
  rules: RegisterOptions;
  name: string;
  setValueAs: FormItemProps["setValueAs"];
}): JSX.Element => {
  return (
    <FormItemContextProvider value={{ rules, name, setValueAs: setValueAs! }}>
      {children}
    </FormItemContextProvider>
  );
};

/**
 * Returns the form item config
 */
export const useFormItem = () => useContext(ctxt);

export type FormItemProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  /**
   * Name of the input associated with this item
   */
  name: string;
  /**
   * (Optional) Description of the input required
   */
  description?: string;
  /**
   * Validation rules for the Form item's children
   */
  rules?: RegisterOptions;
  /**
   * If defined, can be used to transform value before it's saved to the form
   */
  setValueAs?: (value: string) => any;
} & FormTypeUnion;

// these types will be applied if `asFieldSet` is false or undefined
type NormalFormItem = {
  /**
   * If this form item should be rendered as a fieldset
   */
  asFieldSet?: false;
  /**
   * Label text, can either be a string or a custom component.
   */
  label: React.ReactNode | string;
  /**
   * The legend associated with the fieldset, looks like a label
   */
  legend?: React.ReactNode | string;
};

// these types will be applied if `asFieldSet` is true
type FieldSetFormItem = {
  /**
   * If this form item should be rendered as a fieldset
   */
  asFieldSet: true;
  /**
   * The legend associated with the fieldset, looks like a label
   */
  legend: React.ReactNode | string;
  /**
   * Label text, can either be a string or a custom component.
   */
  label?: React.ReactNode | string;
};

type FormTypeUnion = NormalFormItem | FieldSetFormItem;

/**
 * Used to wrap inputs, will render a label and errors associated with `name` passed to it.
 */
const Item = ({
  name,
  children,
  label,
  legend,
  asFieldSet,
  rules = {},
  description,
  setValueAs = (val: string) => val,
  ...rest
}: FormItemProps) => {
  const methods = useFormContext();

  return (
    <FormItemProvider rules={rules} name={name} setValueAs={setValueAs}>
      <div {...rest} className={`form__item ${VanillaItem}`}>
        <FieldSetWrapper isFieldSet={asFieldSet || false}>
          <div className="item__labelcontainer">
            {asFieldSet ? (
              <legend>{legend}</legend>
            ) : (
              <div className="item__label">
                {typeof label === "string" ? (
                  <label htmlFor={name} className="">
                    {label}
                  </label>
                ) : (
                  label
                )}
              </div>
            )}
            {Boolean(description) && (
              <div className="item__description">{description}</div>
            )}
          </div>
          <div className="item__input">{children}</div>
          <div className={`${VanillaError}`} role="alert">
            {
              get(
                methods.formState.errors,
                `${name}.message`
              ) as unknown as string
            }
          </div>
        </FieldSetWrapper>
      </div>
    </FormItemProvider>
  );
};

const FieldSetWrapper = ({
  isFieldSet,
  children,
}: {
  isFieldSet: boolean;
  children: React.ReactNode;
}) => {
  if (isFieldSet) {
    return (
      <fieldset
        style={{
          padding: 0,
          border: 0,
        }}
      >
        {children}
      </fieldset>
    );
  }
  return <>{children}</>;
};

Form.Item = Item;

export default Form;
