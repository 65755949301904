import React from "react";
import BlueprintInput, { InputProps } from "../Input";
import { BrandProp } from "@mojo/types";
import isString from "lodash/isString";
import { VanillaStepper } from "./styles/base.css";
import { MojoStepper } from "./styles/mojo.css";
import { UswitchStepper } from "./styles/uswitch.css";
import { MoneyStepper } from "./styles/money.css";
import { ConfusedStepper } from "./styles/confused.css";

export type StepperProps = BrandProp & {
  /**
   * Icon displayed in the decrease button
   */
  leftIcon?: React.ReactNode;
  /**
   * Icon displayed in the increase button
   */
  rightIcon?: React.ReactNode;
  /**
   * Maximum value
   */
  maximum: number;
  /**
   * Minimum value
   */
  minimum: number;
  /**
   * Callback that runs whenever value is decreased
   */
  onDecrement?: (newValue: string) => void;
  /**
   * Callback that runs whenever value is increased
   */
  onIncrement?: (newValue: string) => void;
  /**
   * Allows editing the value directly within the input
   */
  allowEditing?: boolean;
} & InputProps;

export const Stepper = React.forwardRef<
  HTMLInputElement & {
    Masked: JSX.Element;
  },
  StepperProps
>((props: StepperProps, ref: any) => {
  const {
    brand = "Mojo",
    leftIcon,
    rightIcon,
    id,
    minimum,
    maximum,
    onDecrement,
    onIncrement,
    value = props.minimum,
    allowEditing = false,
    ...rest
  } = props;

  const valueAsNumber = (isString(value) ? parseInt(value) : value) as number;

  const handleDecrement = () => {
    const decrementedValue = valueAsNumber - 1;
    if (decrementedValue >= minimum) {
      onDecrement?.(decrementedValue.toString());
    }
  };

  const handleIncrement = () => {
    const incrementedValue = valueAsNumber + 1;
    if (incrementedValue <= maximum) {
      onIncrement?.(incrementedValue.toString());
    }
  };

  return (
    <BlueprintInput
      ref={ref}
      data-testid="input"
      brand={brand}
      value={value}
      readOnly={!allowEditing}
      onKeyUp={(e) => {
        switch (e.key) {
          case "ArrowUp":
            handleIncrement();
            break;
          case "ArrowDown":
            handleDecrement();
            break;
          default:
            break;
        }
      }}
      leftIcon={
        <button
          type="button"
          className={`
            ${VanillaStepper({ brand })}
            ${MojoStepper}
            ${UswitchStepper}
            ${MoneyStepper}
            ${ConfusedStepper}
          `}
          id={`decrement-${id}`}
          data-testid="decrement"
          disabled={valueAsNumber <= minimum}
          onClick={handleDecrement}
          aria-label="Decrease by 1"
        >
          {leftIcon ? leftIcon : "-"}
        </button>
      }
      rightIcon={
        <button
          type="button"
          className={`
            ${VanillaStepper({ brand })}
            ${MojoStepper}
            ${UswitchStepper}
            ${MoneyStepper}
            ${ConfusedStepper}
          `}
          id={`increment-${id}`}
          data-testid="increment"
          disabled={valueAsNumber >= maximum}
          onClick={handleIncrement}
          aria-label="Increase by 1"
        >
          {rightIcon ? rightIcon : "+"}
        </button>
      }
      {...rest}
      onChange={(e) => {
        props.onChange?.(e);
      }}
      /**
       * We also want to validate if the user presses enter, in case they're in a form and want to submit
       */
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.keyCode === 13) {
          let v = valueAsNumber;
          if (v > maximum) {
            v = maximum;
          }
          if (v < minimum) {
            v = minimum;
          }
          props.onChange?.({
            target: {
              value: v.toString(),
            },
          } as React.ChangeEvent<HTMLInputElement>);
        }
      }}
      onBlur={(e) => {
        if (parseInt(e.target.value) > maximum) {
          e.target.value = maximum.toString();
        }
        if (parseInt(e.target.value) < minimum) {
          e.target.value = minimum.toString();
        }
        props.onChange?.(e);
        props.onBlur?.(e);
      }}
      style={{ textAlign: "center", fontVariantNumeric: "lining-nums" }}
    />
  );
});

export default Stepper;
