import React from "react";
import useMeasure from "react-use-measure";
import { useTemplate } from "../useTemplate";
import { VanillaMain } from "../styles/base.css";

export type MainProps = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * Main should wrap the content of the page, including grid.
 * Only one Main component should be used per page.
 */
export const Main = (props: MainProps) => {
  const { setMainHeight } = useTemplate();
  const [ref, { height }] = useMeasure();

  React.useLayoutEffect(() => {
    setMainHeight(height);
  }, [height]);

  return (
    <main
      {...props}
      className={`${VanillaMain} ${props.className || ""}`}
      ref={ref}
    />
  );
};
