import React from "react";
import { BrandProp, Brands } from "@mojo/types";
import { VanillaSelect } from "./styles/base.css";
import { MojoSelect } from "./styles/mojo.css";
import { UswitchSelect } from "./styles/uswitch.css";
import { MoneySelect } from "./styles/money.css";
import { ConfusedSelect } from "./styles/confused.css";

export type SelectProps = React.InputHTMLAttributes<HTMLSelectElement> &
  BrandProp & {};

const Select = React.forwardRef<HTMLInputElement, SelectProps>(
  ({ brand = "Mojo", ...props }: SelectProps, ref: any) => (
    <select
      {...props}
      className={`
        ${VanillaSelect({ brand })} 
        ${props.className || ""}
        ${MojoSelect}
        ${UswitchSelect}
        ${MoneySelect}
        ${ConfusedSelect}
      `}
      ref={ref}
    />
  )
);

export default Select;
