import React from "react";
import styled, { css, DefaultTheme } from "styled-components";
import { marginBottomVar, VanillaParagraph } from "./styles/paragraph.css";
import { ColorTypes } from "./Headings";
import { assignInlineVars } from "@vanilla-extract/dynamic";

type ParagraphTypes = "large" | "default" | "small" | "caption";

export interface ParagraphProps
  extends React.HTMLAttributes<HTMLParagraphElement> {
  /**
   * The size we'd like the paragraph to be
   * @default default
   */
  size?: ParagraphTypes;
  /**
   * Used to render the paragraph as another element instead of `p`
   */
  as?: "p" | "span";
  /**
   * The font weight
   */
  weight?: 500 | 600 | 700;
  /**
   * Set the text as italic
   */
  italic?: boolean;
  /**
   * The color of the font.
   * @default "inherit"
   */
  color?: ColorTypes;
  children: React.ReactNode;
}

export const Paragraph = ({
  size = "default",
  as = "p",
  italic = false,
  weight,
  color = "inherit",
  children,
  ...props
}: ParagraphProps) => {
  const Component = as;
  return (
    <Component
      {...props}
      className={`${VanillaParagraph({ size, color, italic, weight })} ${props.className || ""}`}
      style={assignInlineVars({
        [marginBottomVar]: as === "span" ? `0px` : "16px",
      })}
    >
      {children}
    </Component>
  );
};
