import React from "react";
import { useTemplate } from "../useTemplate";
import useMeasure from "../../utils/useMeasure";
import { BrandProp } from "@mojo/types";
import { VanillaNav } from "../styles/base.css";

type BasicNavProps = {
  variant?: "transparent" | "white";
  /**
   * Will be rendered on the right side, opposite to the logo
   */
  rightSlot?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement> &
  BrandProp;

/**
 * BasicNav contains simple styles and a logo.
 * It does not include any auth components.
 */
export const BasicNav = ({
  variant = "transparent",
  brand = "Mojo",
  rightSlot,
  ...props
}: BasicNavProps) => {
  const { setNavHeight } = useTemplate();
  const [ref, { height }] = useMeasure();

  React.useLayoutEffect(() => {
    setNavHeight(height);
  }, [height]);

  const logo = React.useMemo(() => {
    switch (brand) {
      default:
      case "Mojo":
        return "https://hosted-assets.mojomortgages.com/images/mojo/mojo-logo.svg";
      case "Uswitch":
        return "https://hosted-assets.mojomortgages.com/images/partners/uswitch-dark.svg";
      case "Money":
        return "https://hosted-assets.mojomortgages.com/images/partners/money-dark.svg";
    }
  }, [brand]);

  return (
    <nav
      {...props}
      className={`mojo__nav ${VanillaNav({ variant })} ${props.className || ""}`}
      ref={ref}
    >
      <img src={logo} width={130} />
      {Boolean(rightSlot) && <div>{rightSlot}</div>}
    </nav>
  );
};
