import React from "react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { marginBottomVar, VanillaHeading } from "./styles/headings.css";

type HeadingTypes = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export type ColorTypes =
  | "inherit"
  | "primary"
  | "secondary"
  | "tertiary"
  | "neutral"
  | "white"
  | "error";

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  /**
   * The size we'd like the heading to be
   * @default h1
   */
  size?: HeadingTypes;
  /**
   * What element should the heading render as?
   * @default h1
   */
  as?: HeadingTypes | "span";
  /**
   * The color of the font.
   * @default "inherit"
   */
  color?: ColorTypes;
  children: React.ReactNode;
}

/**
 * Renders a heading element. Its color and size can ebe customised.
 */
export const Heading = ({
  size = "h1",
  as = "h1",
  color = "inherit",
  children,
  ...props
}: HeadingProps) => {
  const Component = as;
  return (
    <Component
      {...props}
      className={`${VanillaHeading({ size, color })} ${props.className || ""}`}
      style={assignInlineVars({
        [marginBottomVar]: as === "span" ? `0px` : "16px",
        color: "flongle",
      })}
    >
      {children}
    </Component>
  );
};
