import { BrandProp } from "@mojo/types";
import React from "react";
import { Guid } from "@mojo/utils";
import {
  VanillaRadioButton,
  VanillaRadioButtonCheckmark,
  VanillaRadioButtonInput,
  VanillaRadioButtonLabel,
  VanillaRadioButtonText,
  VanillaRadioButtonTextContainer,
  VanillaRadioButtonTextSubtext,
} from "./styles/base.css";
import {
  MojoRadioButtonCheckmark,
  MojoRadioButtonLabel,
  MojoRadioButtonSubText,
} from "./styles/mojo.css";
import {
  UswitchRadioButtonCheckmark,
  UswitchRadioButtonLabel,
  UswitchRadioButtonSubtext,
} from "./styles/uswitch.css";
import {
  MoneyRadioButtonCheckmark,
  MoneyRadioButtonLabel,
  MoneyRadioButtonSubtext,
} from "./styles/money.css";
import {
  ConfusedRadioButtonCheckmark,
  ConfusedRadioButtonLabel,
  ConfusedRadioButtonSubtext,
} from "./styles/confused.css";

export type RadioProps = React.InputHTMLAttributes<HTMLInputElement> &
  BrandProp & {
    /**
     * Whether the radio control should be hidden.
     * @default false
     */
    hideControl?: boolean;
    subText?: React.ReactNode | undefined;
    value: string;
    children: React.ReactNode;
  };

const RadioButton = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      children,
      brand = "Mojo",
      hideControl = false,
      subText = undefined,
      name = Guid(),
      ...props
    }: RadioProps,
    ref: any
  ) => {
    const id = props.id ?? `${name}-${props.value}`;
    return (
      <div className={`${VanillaRadioButton({ brand, hideControl })}`}>
        <input
          type="radio"
          {...props}
          className={`
            ${VanillaRadioButtonInput} 
            ${props.className || ""}
          `}
          name={name}
          id={id}
          ref={ref}
        />
        <label
          htmlFor={id}
          id={`${id}-label`}
          className={`
            radio__label 
            ${VanillaRadioButtonLabel}
            ${MojoRadioButtonLabel}
            ${UswitchRadioButtonLabel}
            ${MoneyRadioButtonLabel}
            ${ConfusedRadioButtonLabel}
          `}
        >
          <div
            className={`
              radio__checkmark
              ${VanillaRadioButtonCheckmark}
              ${MojoRadioButtonCheckmark}
              ${UswitchRadioButtonCheckmark}
              ${MoneyRadioButtonCheckmark}
              ${ConfusedRadioButtonCheckmark}
            `}
          />
          <div
            className={`
              radio__textContainer
              ${VanillaRadioButtonTextContainer}
            `}
          >
            <div
              className={`
                radio__text
                ${VanillaRadioButtonText}
                ${VanillaRadioButtonTextSubtext}
              `}
            >
              {children}
            </div>
            {subText && (
              <div
                className={`
                  radio__subtext
                  ${VanillaRadioButtonTextSubtext}
                  ${MojoRadioButtonSubText}
                  ${UswitchRadioButtonSubtext}
                  ${MoneyRadioButtonSubtext}
                  ${ConfusedRadioButtonSubtext}
                `}
              >
                {subText}
              </div>
            )}
          </div>
        </label>
      </div>
    );
  }
);

export default RadioButton;
