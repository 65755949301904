import { VanillaGrid } from "../styles/base.css";

export interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

/*
Template is for placing content in the main body of the page. 
It has 12 columns on desktop, 6 on tablet, 3 on mobile.
┌─┬─┬─┬─┬─┬─┬─┬─┬─┬─┬─┬─┐  ┌─┬─┬─┬─┬─┬─┐  ┌──┬──┬──┐
│ │ │ │ │ │ │ │ │ │ │ │ │  │ │ │ │ │ │ │  │  │  │  │
│ │ │ │ │ │ │ │ │ │ │ │ │  │ │ │ │ │ │ │  │  │  │  │
│ │ │ │ │ │ │ │ │ │ │ │ │  │ │ │ │ │ │ │  │  │  │  │
│ │ │ │ │ │ │ │ │ │ │ │ │  │ │ │ │ │ │ │  │  │  │  │
└─┴─┴─┴─┴─┴─┴─┴─┴─┴─┴─┴─┘  └─┴─┴─┴─┴─┴─┘  └──┴──┴──┘
*/
export const Grid = (props: GridProps) => (
  <div
    {...props}
    className={`mojo__grid ${VanillaGrid} ${props.className || ""}`}
  />
);
