import { BrandProp } from "@mojo/types";
import React from "react";
import Masked from "./Masked";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import useMeasure from "../utils/useMeasure";
import {
  VanillaInput,
  VanillaInputContainer,
  VanillaInputIcons,
  extraPaddingLeftVar,
  extraPaddingRightVar,
} from "./styles/base.css";
import {
  MojoInput,
  MojoInputIconLeft,
  MojoInputIconRight,
} from "./styles/mojo.css";
import { UswitchInput } from "./styles/uswitch.css";
import {
  MoneyInput,
  MoneyInputIconLeft,
  MoneyInputIconRight,
} from "./styles/money.css";
import { ConfusedInput } from "./styles/Confused.css";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  BrandProp & {
    /**
     * Display an icon (as a react component or string) left of the input
     */
    leftIcon?: React.ReactNode | string;
    /**
     * Display an icon (as a react component or string) right of the input
     */
    rightIcon?: React.ReactNode | string;
  };

// eslint-disable-next-line react/display-name
const Input = React.forwardRef<
  HTMLInputElement & {
    Masked: JSX.Element;
  },
  InputProps
>(({ brand = "Mojo", leftIcon, rightIcon, ...props }: InputProps, ref: any) => {
  const [leftIconRef, { width: leftIconWidth }] = useMeasure();
  const [rightIconRef, { width: rightIconWidth }] = useMeasure();

  return (
    <div
      className={`${brand} ${VanillaInputContainer({ brand })}`}
      style={assignInlineVars({
        [extraPaddingRightVar]: `${rightIconWidth}px`,
        [extraPaddingLeftVar]: `${leftIconWidth}px`,
      })}
    >
      <input
        {...props}
        className={`
          ${VanillaInput} 
          ${props.className || ""}
          ${MojoInput}
          ${UswitchInput}
          ${MoneyInput}
          ${ConfusedInput}
        `}
        ref={ref}
      />
      {leftIcon && (
        <div
          className={`
            input__leftIcon 
            ${VanillaInputIcons}
            ${MojoInputIconLeft}
            ${MoneyInputIconLeft}
          `}
          style={{
            left: -1,
          }}
          ref={leftIconRef}
        >
          {leftIcon}
        </div>
      )}
      {rightIcon && (
        <div
          className={`
            input__rightIcon 
            ${VanillaInputIcons}
            ${MojoInputIconRight}
            ${MoneyInputIconRight}
          `}
          style={{
            right: -1,
          }}
          ref={rightIconRef}
        >
          {rightIcon}
        </div>
      )}
    </div>
  );
});

const InputNamespace = Object.assign(Input, { Masked });

export default InputNamespace;
