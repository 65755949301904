import React from "react";
import { BrandProp, Brands } from "@mojo/types";
import { Guid } from "@mojo/utils";
import {
  VanillaCheckboxCheckButton,
  VanillaCheckboxCheckButtonCheckmark,
  VanillaCheckboxCheckButtonInput,
  VanillaCheckboxCheckButtonLabel,
  VanillaCheckboxCheckButtonText,
} from "./checkButtonStyles/base.css";
import {
  MojoCheckButtonCheckmark,
  MojoCheckButtonLabel,
} from "./checkButtonStyles/mojo.css";
import {
  UswitchCheckButtonCheckmark,
  UswitchCheckButtonLabel,
} from "./checkButtonStyles/uswitch.css";
import {
  MoneyCheckButtonCheckmark,
  MoneyCheckButtonLabel,
} from "./checkButtonStyles/money.css";
import {
  ConfusedCheckButtonCheckmark,
  ConfusedCheckButtonLabel,
} from "./checkButtonStyles/confused.css";
import {
  MojoCheckboxCheckmark,
  MojoCheckboxLabel,
} from "./checkboxStyles/mojo.css";
import {
  UswitchCheckboxCheckmark,
  UswitchCheckboxLabel,
} from "./checkboxStyles/uswitch.css";
import {
  MoneyCheckboxCheckmark,
  MoneyCheckboxLabel,
} from "./checkboxStyles/money.css";
import {
  ConfusedCheckboxCheckmark,
  ConfusedCheckboxLabel,
} from "./checkboxStyles/confused.css";

export type CheckProps = React.InputHTMLAttributes<HTMLInputElement> &
  BrandProp & {
    /**
     * Whether the checkbox should be hidden.
     * @default false
     */
    hideControl?: boolean;
    /**
     * Displays the checkbox as a button
     * @default true
     */
    asCheckButton?: boolean;
    value: string;
    children: React.ReactNode;
  };

const CheckButton = React.forwardRef<
  HTMLInputElement & {
    Controlled: JSX.Element;
  },
  CheckProps
>(
  (
    {
      children,
      brand = "Mojo",
      hideControl = false,
      asCheckButton = true,
      className,
      name = Guid(),
      ...props
    }: CheckProps,
    ref: any
  ) => {
    const id = props.id ?? `${name}-${props.value}`;
    return (
      <div
        className={`
          ${VanillaCheckboxCheckButton({ asCheckButton, hideControl, brand })}
          ${className || ""}
        `}
        data-testid={id}
      >
        <input
          type="checkbox"
          {...props}
          className={`
            ${VanillaCheckboxCheckButtonInput}
          `}
          id={id}
          ref={ref}
        />
        <label
          htmlFor={id}
          id={`${id}-label`}
          className={`
            ${VanillaCheckboxCheckButtonLabel}
            ${MojoCheckButtonLabel}
            ${UswitchCheckButtonLabel}
            ${MoneyCheckButtonLabel}
            ${ConfusedCheckButtonLabel}
            ${MojoCheckboxLabel}
            ${UswitchCheckboxLabel}
            ${MoneyCheckboxLabel}
            ${ConfusedCheckboxLabel}
          `}
        >
          <span
            className={`
              check__checkmark
              ${VanillaCheckboxCheckButtonCheckmark}
              ${MojoCheckButtonCheckmark}
              ${UswitchCheckButtonCheckmark}
              ${MoneyCheckButtonCheckmark}
              ${ConfusedCheckButtonCheckmark}
              ${MojoCheckboxCheckmark}
              ${UswitchCheckboxCheckmark}
              ${MoneyCheckboxCheckmark}
              ${ConfusedCheckboxCheckmark}
            `}
          />
          <div
            className={`
              check__text
              ${VanillaCheckboxCheckButtonText}
            `}
          >
            {children}
          </div>
        </label>
      </div>
    );
  }
);

export default CheckButton;
