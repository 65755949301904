import { Theme } from "@mojo/blueprint/Theme";
import { theme } from "@mojo/blueprint/Theme/theme.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import GlobalStyle from "./GlobalStyle";

document.body.classList.add(theme);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <Theme>
            <GlobalStyle />
            <App />
        </Theme>
    </React.StrictMode>,
);
