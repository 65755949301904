export const readCookie = (key: string): string | undefined =>
  document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${key}=`))
    ?.split("=")[1];

export const setCookie = (
  domain: string | undefined,
  key: string,
  value: string,
  expirySeconds = 31556926 // one year
) => {
  const now = new Date();
  const ms = expirySeconds * 1000;
  const expireTime = new Date(now.getTime() + ms);
  document.cookie = `${key}=${value}; expires=${expireTime.toUTCString()}; domain=${domain ?? get_top_domain()}; path=/`;
};

/**
 * Returns top level domain for current page
 * from https://stackoverflow.com/a/11319865/8685682
 */
function get_top_domain() {
  var i,
    h,
    weird_cookie = "weird_get_top_level_domain=cookie",
    hostname = document.location.hostname.split(".");
  for (i = hostname.length - 1; i >= 0; i--) {
    h = hostname.slice(i).join(".");
    document.cookie = weird_cookie + ";domain=." + h + ";";
    if (document.cookie.indexOf(weird_cookie) > -1) {
      // We were able to store a cookie! This must be it
      document.cookie =
        weird_cookie.split("=")[0] +
        "=;domain=." +
        h +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      return h;
    }
  }
}

export const preferencesCookie = "allow-cookies";
type Preference = "essential-only" | "all";

export const getPreferences = (): Preference | undefined => {
  const val = readCookie(preferencesCookie);
  if (val && (val === "essential-only" || val === "all")) return val;
  return undefined;
};
