"use client";
import React from "react";
import Button, { ButtonType } from "../Button";
import { BrandProp } from "@mojo/types";
import { createPortal } from "react-dom";
import { getPreferences, preferencesCookie, setCookie } from "./utils/cookies";
import {
  VanillaCookieBanner,
  VanillaCookieBannerButton,
  VanillaCookieBannerButtons,
  VanillaCookieBannerOverlay,
  VanillaCookieBannerText,
} from "./styles/base.css";
import { MojoCookieBanner } from "./styles/mojo.css";
import { OthersCookieBanner } from "./styles/other.css";

export type CookieBannerProps = React.HTMLAttributes<HTMLDivElement> &
  BrandProp & {
    /**
     * Event handler called when the user clicks to accept essential cookies
     */
    onClickEssential?: () => void;
    /**
     * Event handler called when the user clicks to accept all cookies
     */
    onClickAll?: () => void;
    /**
     * Optional domain for which to set the cookie
     */
    domain?: string;
  };

/**
 * A branded cookie banner component
 */
const CookieBanner = React.forwardRef<HTMLDivElement, CookieBannerProps>(
  (
    {
      brand = "Mojo",
      onClickEssential = () => {
        /* do nothing */
      },
      onClickAll = () => {
        /* do nothing */
      },
      domain,
      ...props
    }: CookieBannerProps,
    ref
  ) => {
    const [isOpen, setIsOpen] = React.useState(false);

    // ensures the cookie logic is only ran on the client
    React.useEffect(() => {
      if (getPreferences()) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    }, []);

    React.useEffect(() => {
      const cookieBanner = document.querySelector(
        "#cookieBanner"
      ) as HTMLInputElement;
      if (cookieBanner) cookieBanner.focus();
    }, [isOpen]);

    /**
     * Runs when user allows essential cookies only
     */
    const doClickEssential = () => {
      onClickEssential();
      setCookie(domain, preferencesCookie, "essential-only");
      setIsOpen(false);
    };

    /**
     * Runs when user allows all cookies
     */
    const doClickAll = () => {
      onClickAll();
      setCookie(domain, preferencesCookie, "all");
      setIsOpen(false);
    };

    return isOpen
      ? createPortal(
          <div className={`${VanillaCookieBannerOverlay}`}>
            <div
              id={"cookieBanner"}
              ref={ref}
              role="region"
              aria-label="Cookie banner"
              tabIndex={0}
              className={`
                ${VanillaCookieBanner({ brand })}
                ${OthersCookieBanner}
                ${MojoCookieBanner}
              `}
              {...props}
            >
              <div
                className={`
                  cookieBanner__text
                  ${VanillaCookieBannerText}
                `}
                role="dialog"
                aria-label="Cookie disclaimer"
              >
                <p>
                  By clicking ‘Accept all cookies’, you agree to the storing of
                  cookies on your device to support the user experience and
                  analyse site usage. Read more on our{" "}
                  <a
                    href="https://mojomortgages.com/cookies/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cookie Policy page
                  </a>
                  .
                </p>
              </div>
              <div
                className={`
                  cookieBanner__buttons
                  ${VanillaCookieBannerButtons}
                `}
                role="group"
                aria-label="Cookie buttons"
              >
                <Button
                  brand={brand}
                  className={`
                    cookieBanner__button cookieBanner__essentialButton
                    ${VanillaCookieBannerButton}
                  `}
                  variant={ButtonType.Secondary}
                  onClick={doClickEssential}
                >
                  Essential cookies only
                </Button>
                <Button
                  brand={brand}
                  className={`
                    cookieBanner__button cookieBanner__allButton
                    ${VanillaCookieBannerButton}
                  `}
                  variant={ButtonType.Primary}
                  onClick={doClickAll}
                >
                  Accept all cookies
                </Button>
              </div>
            </div>
          </div>,
          document.body
        )
      : null;
  }
);

export default CookieBanner;
